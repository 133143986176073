const initialState = {
    lang: {}
  };
  
  export default (state = initialState, action) => {
    switch (action.type) {
      case "LOCALE_START":
        return {
          ...state,
          lang: {}
        };
      case "LOCALE_FINISH":
        return {
          ...state,
          lang: action.payload.data
        };
      default:
        return state;
    }
  };
  